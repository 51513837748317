import * as React from 'react';
import Tooltip from 'rc-tooltip';
import './_rc-tooltip.scss';

import { FormikProps, Field, FieldProps } from 'formik';
import { Venues } from '@weddingspot/ws-api-client';

import { email } from '../../utils/validators';

import styles from './FormReportDMCA.module.scss';

const validateEmail = (emailAddr: string | null) => {
    if (!emailAddr) {
        return 'Required';
    }
    if (email(emailAddr)) {
        return 'Invalid email address';
    }

    return;
};

const validateMessage = (message: string | null) => {
    if (!message) {
        return 'Required';
    }

    return;
};

/**
 * The props for the underlying formik form
 */
export interface ReportDMCAFormProps {
    report_reason: Venues.DMCATakedownReason;
    email: string;
    message: string;
}

/**
 * Formik form for reporting DMCA violations
 */
export default class ReportDMCAForm extends React.Component<FormikProps<ReportDMCAFormProps>> {
    render() {
        const props = this.props;

        const radioData = [
            {
                value: Venues.DMCATakedownReason.inappropriate_content,
                label: 'Inappropriate content',
                helpText: 'Includes content that is inappropriate or irrelevant to events at my venue',
            },
            {
                value: Venues.DMCATakedownReason.not_my_venue,
                label: 'Not my venue',
                helpText: 'The photo(s) reported are not of my venue',
            },
            {
                value: Venues.DMCATakedownReason.infringes_my_right,
                label: 'Infringes my rights',
                helpText: 'Privacy, copyright, and other legal complaints',
            },
        ];

        return (
            <div className={styles.className}>
                <div>
                    <div className={styles.section}>
                        <div className={styles.header}>
                            What's the issue? <span>*</span>
                        </div>
                        <div>
                            <Field
                                name='report_reason'
                                value={props.values.report_reason}
                                render={(fieldProps: FieldProps<any, ReportDMCAFormProps>) => {
                                    return (
                                        <div>
                                            {radioData.map(({ value, label, helpText }, idx) => {
                                                return (
                                                    <div className={styles.radio} key={idx}>
                                                        <label>
                                                            <input
                                                                type='radio'
                                                                checked={fieldProps.form.values.report_reason === value}
                                                                {...fieldProps.field}
                                                                onChange={() => {
                                                                    props.setFieldValue('report_reason', value);
                                                                }}
                                                            />
                                                            {label}
                                                            <Tooltip placement='top' trigger={['hover']} overlay={<span>{helpText}</span>}>
                                                                <span className={styles.helpText}>
                                                                    <i className='icon-hb-nx-question-mark' />
                                                                </span>
                                                            </Tooltip>
                                                        </label>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.header}>
                            Your email address <span>*</span>
                        </div>
                        <div>
                            <Field
                                name='email'
                                placeholder='john.doe@email.com'
                                type='text'
                                value={props.values.email}
                                validate={validateEmail}
                            />
                        </div>
                        {props.errors.email && props.touched.email && <div className={styles.error}>{props.errors.email}</div>}
                    </div>
                    <div className={styles.section}>
                        <div className={styles.header}>
                            Additional information <span>*</span>
                        </div>
                        <div className={styles.subheader}>
                            Which photo(s) should be taken down; your role i.e. employee of the venue, owner of the venue, photographer)
                        </div>
                        <div>
                            <Field name='message' component='textarea' value={props.values.message} validate={validateMessage} />
                        </div>
                        {props.errors.message && props.touched.message && <div className={styles.error}>{props.errors.message}</div>}
                    </div>

                    <div className={styles.formError}>{!!props.status && props.status}</div>
                </div>
            </div>
        );
    }
}
