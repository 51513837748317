import { VendorsApi, Venues } from "@weddingspot/ws-api-client";

export type ReviewsCountCallback = (count: number) => void;

export function reviewsCount(setUnreadReviewsCount: ReviewsCountCallback) {
    const reviewInfoKey = 'reviewData'; 
    let reviewInfo;
    // Check session storage for unread review count
    const reviewInfoStr = window.sessionStorage.getItem(reviewInfoKey);
    if (reviewInfoStr) {
        try {
            reviewInfo = JSON.parse(reviewInfoStr);
        } catch (err) {
            throw new Error("[ReviewsCount] Unable to parse data stored in session storage")
        }
    }

    // Only use the review count from session storage if it is not expired that is not more than 30 mins old
    let now = new Date().getTime();
    let expirationTime = 30*60*1000; // 30 minutes
    if (reviewInfo && (now - reviewInfo.timestamp <= expirationTime)) {
        setUnreadReviewsCount(reviewInfo.unread_count);
    } else {
        window.sessionStorage.removeItem(reviewInfoKey);
        VendorsApi.fetchUnreadReviewsCount()
            .then((resp: Venues.ReviewUnreadCount) => {
                if (resp && resp.success) {
                    setUnreadReviewsCount(resp.unread_count)
                    reviewInfo = {
                        timestamp: new Date().getTime(),
                        unread_count: resp.unread_count,
                    };
                    try {
                        sessionStorage.setItem(reviewInfoKey, JSON.stringify(reviewInfo));
                    } catch (err) {
                        throw new Error("[ReviewsCount] Error when setting data in session storage")
                    }
                }
            })
            .catch((e) => {
                console.error(e);
            });
    }
}
