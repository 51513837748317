import React from 'react';
import { CheckoutVenueRecommendationsCardInterface } from '../../../checkout/Cards/Card';

interface ChooseHotelsStepProps {
    hotelRecommendations: CheckoutVenueRecommendationsCardInterface[];
    changeIsChecked: (value: number | string) => void;
}

import styles from './VenuesShoppingCartInquiryHotels.module.scss';
import CheckoutVenueRecommendationsCardsList from '../../../checkout/Cards/CardsList';

const ChooseHotelsStep = ({ hotelRecommendations, changeIsChecked }: ChooseHotelsStepProps) => {
    return (
        <div>
            <h2>Need hotel rooms for your guests ?</h2>
            <div className={styles.hotelsRecommendationsFormTitleBody}>
                <div>Hotel room blocks are a free and easy way to get the best rates out there. We can help you:</div>
                <div>
                    <ul>
                        <li>Receive custom quotes</li>
                        <li>Access discounted rates</li>
                        <li>And ensure that you get the best possible price for your guest's hotel rooms</li>
                    </ul>
                </div>
                <div>Hotels we think you’ll love:</div>
            </div>
            <div className={styles.chooseCardsWrapper}>
                <CheckoutVenueRecommendationsCardsList list={hotelRecommendations} changeIsChecked={changeIsChecked} />
            </div>
        </div>
    );
};

export default ChooseHotelsStep;
