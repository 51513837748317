import * as React from 'react';
import cn from 'classnames';

import styles from './Footer.module.scss';

import weddingSpotLogo from '../../assets/images/footer/logo.png';
import cventLogo from '../../assets/images/footer/cvent-logo.png';

import facebookIcon from '../../assets/images/footer/facebook.png';
import instagramIcon from '../../assets/images/footer/instagram.png';
import pinterestIcon from '../../assets/images/footer/pinterest.png';
import { YOUR_PRIVACY_CHOICES, YOUR_PRIVACY_CHOICES_URL } from '../appointments/constants';

export class Footer extends React.Component<{}> {


    render() {
        return (
            <div className={styles.className}>
                <div className={styles.container}>
                    <div className={styles.trademarks}>
                        <div className={styles.logoWrapper}>
                            <a href='/' className={styles.wsLogo}>
                                <img src={weddingSpotLogo?.src || weddingSpotLogo} alt='Wedding Spot logo' />
                            </a>
                            <span className={styles.trademarkSeparator}>|</span>
                            <a className={styles.cventLogo} href='https://cvent.com/' target='_blank'>
                                <img src={cventLogo?.src || cventLogo} alt='Cvent Inc.' title='cvent.com' />
                            </a>
                        </div>
                        <div className={styles.copyright}>© {new Date().getFullYear()} Cvent Inc.</div>
                        <div className={cn(styles.termsLinks, styles.hiddenMobile)}>
                            <a href='/privacy/'>privacy policy</a>
                            <span className={styles.termsLinksSeparator}>|</span>
                            <a href='/terms-of-service/'>terms of service</a>
                        </div>
                        <div className={cn(styles.termsLinks, styles.hiddenMobile)}>
                            <a href={YOUR_PRIVACY_CHOICES_URL} target='_blank'>{YOUR_PRIVACY_CHOICES}</a>
                        </div>
                        <div className={styles.socialWrapper}>
                            <a href='https://www.facebook.com/weddingspotofficial/' className={styles.socialLink}>
                                <img
                                    src={facebookIcon?.src || facebookIcon}
                                    alt='Wedding Spot Facebook Profile'
                                    title='Wedding Spot Facebook Profile'
                                />
                            </a>
                            <a href='https://www.instagram.com/weddingspotofficial/' className={styles.socialLink}>
                                <img
                                    src={instagramIcon?.src || instagramIcon}
                                    alt='Wedding Spot Instagram'
                                    title='Wedding Spot Instagram'
                                />
                            </a>
                            <a href='https://pinterest.com/atweddingspot/' className={styles.socialLink}>
                                <img
                                    src={pinterestIcon?.src || pinterestIcon}
                                    alt='Wedding Spot Pinterest Profile'
                                    title='Wedding Spot Pinterest Profile'
                                />
                            </a>
                        </div>
                    </div>

                    <div className={cn(styles.nav)}>
                        <ul>
                            <li className={styles.headline}>Wedding Spot</li>
                            <li>
                                <a href='/'>Home</a>
                            </li>
                            <li>
                                <a href='/aboutus/'>About</a>
                            </li>
                            {/*<li><a href="https://honeybook.com/careers/" target="_blank">Careers</a></li>*/}
                            {/*we will bring it back later when we have more time to prep that page and not redirect to HB...*/}
                        </ul>
                        <ul>
                            <li className={styles.headline}>Services</li>
                            <li>
                                <a href='/wedding-venues/'>Find Venue</a>
                            </li>
                            <li>
                                <a href='/locations/'>Venues By Region</a>
                            </li>
                            <li className={styles.fullWidth}>
                                <a href='/hotels/' target='_blank'>
                                    Hotel Room Blocks
                                </a>
                            </li>
                        </ul>
                        <ul>
                            <li className={styles.headline}>News</li>
                            <li>
                                <a href='/press/'>Press Releases</a>
                            </li>
                            <li>
                                <a href='/news/'>In The News</a>
                            </li>
                            <li>
                                <a href='/blog/'>Spot Blog</a>
                            </li>
                        </ul>
                        <ul>
                            <li className={styles.headline}>Terms</li>
                            <li>
                                <a href='/terms-of-service/'>Terms Of Service</a>
                            </li>
                            <li>
                                <a href='/privacy/'>Privacy Policy</a>
                            </li>
                            <li>
                                <a href={YOUR_PRIVACY_CHOICES_URL} target='_blank'>{YOUR_PRIVACY_CHOICES}</a>
                            </li>
                        </ul>
                        <ul>
                            <li className={styles.headline}>Help</li>
                            <li>
                                <a href='/contactus/'>Contact Us</a>
                            </li>
                            <li>
                                <a href='/faq/'>FAQ</a>
                            </li>
                            <li>
                                <a href='/how-it-works/'>How It Works</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;
