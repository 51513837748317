import * as React from 'react';
import cn from 'classnames';
import Helmet from 'react-helmet';
import './Global.module.scss';

/*
Component for manupulate global things. Currently work only with body className
but feel free to extend this functionaly to something more
*/
export const Global = (props: { className?: string; preventScrolling?: 'always' | 'onlyMobile' }) => {
    return (
        <Helmet>
            <body
                className={cn(
                    props.className,
                    { preventScrolling: props.preventScrolling === 'always' },
                    { preventScrollingMobile: props.preventScrolling === 'onlyMobile' }
                )}
            />
        </Helmet>
    );
};
