import * as React from 'react';
import { useState } from 'react';
import Slider from 'react-slick';
import cn from 'classnames';
import { useWiderThan } from '../../utils/hooks';
import { CardItemComponent, CartItem } from '../shopping-cart';
import { formatCventImage } from '../../utils/imageUtils';
import styles from './VenuesShoppingCartModalList.module.scss';

interface VenuesShoppingCartModalListProps {
    items: CartItem[];
    onClickRemove?: (id: string | number) => void;
}

const sliderSettings = {
    className: styles.sliderContainer,
    infinite: false,
    slidesToShow: 1.3,
    speed: 500,
    arrows: false,
};

const VenuesShoppingCartModalList = (props: VenuesShoppingCartModalListProps) => {
    const [showAll, setShowAll] = useState<boolean>(false);
    const isMobile = !useWiderThan(769);

    const getCountOfItemsToShow = () => {
        if (showAll) {
            return props.items.length;
        } else {
            return props.items.length > 4 ? 3 : 4;
        }
    };

    if (props.items.length === 0) {
        return null;
    }

    if (isMobile) {
        return (
            <div className={styles.slider}>
                <Slider {...sliderSettings}>
                    {props.items.map((item: CartItem, idx: number) => (
                        <div key={idx} className={`${styles.item} ${styles.sliderItem}`}>
                            <CardItemComponent
                                id={item.id}
                                title={item.name}
                                subtitle={item.location}
                                key={item.id}
                                rating={item.rating}
                                image={item.imageUrl}
                                onClickRemove={props.onClickRemove}
                            />
                        </div>
                    ))}
                </Slider>
            </div>
        );
    }

    const makePoweredBySubtitle = (isDestination?: boolean) => (isDestination == false ? null : <b>by Destination Weddings</b>);

    return (
        <div className={styles.list}>
            {props.items.slice(0, getCountOfItemsToShow()).map((item: CartItem, idx: number) => (
                <div key={idx} className={styles.item}>
                    <CardItemComponent
                        id={item.id}
                        title={item.name}
                        subtitle={item.location}
                        key={item.id}
                        rating={item.rating}
                        image={item.imageUrl}
                        additionalSubtitle={makePoweredBySubtitle(item.isDestination)}
                    />
                </div>
            ))}
            {props.items.length > 4 && !showAll && (
                <div
                    className={cn(styles.item, styles.item__last)}
                    onClick={() => {
                        setShowAll(true);
                    }}
                >
                    <div className={styles.images}>
                        {props.items.slice(3, props.items.length).map((item: CartItem, idx: number) => (
                            <div className={styles.image} key={idx}>
                                {item.imageUrl && <img src={formatCventImage({ src: item.imageUrl })} />}
                            </div>
                        ))}
                    </div>
                    <div className={styles.viewMore}>
                        <p>+ {props.items.length - 3} more</p>
                        <p>View All</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default React.memo(VenuesShoppingCartModalList);
