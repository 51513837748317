import * as React from 'react';

import SimpleTrackedLink from './SimpleTrackedLink';
import HeaderRightLinksAuth, { HeaderRightLinksAuthBaseProps } from './HeaderRightLinksAuth';
import MessageCountBadge from './MessageCountBadge';
import { HeaderContext } from './HeaderContext';

import styles from './Header.module.scss';
import { TrackedLogoutLink } from './TrackedLogoutLink';

// Main links component
const HeaderRightLinksCouple: React.SFC<HeaderRightLinksAuthBaseProps> = (props) => {
    const { headerItemClicked } = React.useContext(HeaderContext);

    const HeaderTrackedLink = (linkProps: { label: string; url: string; children?: React.ReactNode }) => {
        return <SimpleTrackedLink {...linkProps} onClick={headerItemClicked} />;
    };

    const renderMenuItems = () => {
        return (
            <>
                <li>
                    <HeaderTrackedLink label='Messages' url='/myaccount/messages/'>
                        Messages
                        {!!props.unreadMessageCount && <MessageCountBadge count={props.unreadMessageCount} />}
                    </HeaderTrackedLink>
                </li>
                <li>
                    <HeaderTrackedLink label='Favorites' url='/myaccount/favorites/' />
                </li>
                <li>
                    <HeaderTrackedLink label='Spot Estimates' url='/myaccount/spot-estimates/' />
                </li>
                <li>
                    <HeaderTrackedLink label='Inquiries' url='/myaccount/appointments/' />
                </li>
                <li className={styles.divider} />
                <li>
                    <HeaderTrackedLink label='Help' url='/faq/' />
                </li>
                <li>
                    <HeaderTrackedLink label='Account Settings' url='/myaccount/contactinfo/' />
                </li>
                <li>
                    <TrackedLogoutLink />
                </li>
            </>
        );
    };

    return <HeaderRightLinksAuth {...props} renderMenuItems={renderMenuItems} />;
};

export default HeaderRightLinksCouple;
