import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';

import './scss/hybrid-material-textarea.module.scss';

export interface OwnProps {
    label: string;
    placeholder?: string;
    handleOnBlur?: (value: string) => void;
    ariaLabel?: string;
}
export type HybridMaterialTextAreaProps = WrappedFieldProps & OwnProps;

export const HybridMaterialTextArea: React.SFC<HybridMaterialTextAreaProps> = (props) => {
    const {
        label,
        input: { onFocus, onBlur, ...input },
        handleOnBlur,
        meta: { error, touched },
        placeholder,
        ariaLabel,
    } = props;

    return (
        <div className='HybridMaterialTextArea'>
            <label className='HybridMaterialTextArea--label'>{label}</label>
            <textarea
                {...input}
                placeholder={placeholder}
                className={'HybridMaterialTextArea--textarea' + (touched && error ? ' HybridMaterialTextArea--textarea__has-error' : '')}
                onBlur={(e: React.SyntheticEvent<HTMLTextAreaElement>) => {
                    onBlur(e);
                    !!handleOnBlur && handleOnBlur(e.currentTarget.value);
                }}
                aria-label={ariaLabel}
            />
            {touched && error && <div className='HybridMaterialTextArea--error'>{error}</div>}
        </div>
    );
};

export default HybridMaterialTextArea;
