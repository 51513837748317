import * as React from 'react';
interface SimpleTrackedLinkProps {
    label: string;
    url: string;
    onClick: (name: string) => void;
    className?: string;
}

// Tracked link component for convenience in markup definition below
const SimpleTrackedLink: React.SFC<SimpleTrackedLinkProps> = (props) => {
    return (
        <a href={props.url} onClick={() => props.onClick(props.label)} className={props.className}>
            {!props.children ? props.label : props.children}
        </a>
    );
};

export default SimpleTrackedLink;
