import * as React from 'react';

import { WrappedFieldProps } from 'redux-form';

import './scss/material-text-input.module.scss';
import './scss/material-select-input.module.scss';

export interface OwnProps {
    label: string;
    placeholder?: string;
    choices: [string, string][];
    disableSearch: boolean;
    ariaLabel?: string;
}
export type MaterialSelectInputProps = WrappedFieldProps & OwnProps;

export interface State {
    isOpen: boolean;
    valuePresentation: string;
    selectedValue: string;
    choices: [string, string][];
    ariaLabel?: string;
}

export class MaterialSelectInput extends React.Component<MaterialSelectInputProps, State> {
    input: HTMLInputElement;

    constructor(props: MaterialSelectInputProps) {
        super(props);

        this.state = {
            isOpen: false,
            selectedValue: props.meta.initial || '',
            valuePresentation: this.tryGetPresentationForValue(props.meta.initial, ''),
            choices: props.choices,
            ariaLabel: props.ariaLabel,
        };
    }

    componentDidUpdate(prevProps: MaterialSelectInputProps) {
        // If choices were updated via props, make sure we update the initial state as well
        if (JSON.stringify(prevProps.choices) !== JSON.stringify(this.props.choices) && this.props.choices !== this.state.choices) {
            this.setState({ choices: this.props.choices });
        }
    }

    handleFocus(e: React.FocusEvent<HTMLInputElement>) {
        const {
            input: { onFocus },
            placeholder,
        } = this.props;
        e.currentTarget.placeholder = (placeholder && placeholder) || '';
        onFocus(e);

        this.setState({ isOpen: true });
    }

    handleBlur(e: React.SyntheticEvent<HTMLInputElement>) {
        const {
            input: { onBlur },
        } = this.props;
        e.currentTarget.placeholder = '';

        if (!this.state.selectedValue) {
            e.currentTarget.value = '';
        }

        onBlur(this.state.selectedValue);

        this.setState({
            isOpen: false,
            valuePresentation: this.state.selectedValue ? this.state.valuePresentation : '',
            choices: this.state.selectedValue ? this.state.choices : this.props.choices,
        });
    }

    render() {
        const {
            label,
            input: { onFocus, onBlur, onChange, ...input },
            meta: { error, touched },
        } = this.props;

        let { isOpen, valuePresentation, selectedValue, choices, ariaLabel } = this.state;

        // On re-render the state may be cleared by initial value set, so
        // make sure we also update the label
        const hasValue = input.value.length !== 0;
        if (hasValue && !valuePresentation) {
            valuePresentation = this.tryGetPresentationForValue(input.value, '');
        }

        return (
            <div className='MaterialTextInput MaterialSelectInput'>
                <input
                    onFocus={(e: React.FocusEvent<HTMLInputElement>) => this.handleFocus(e)}
                    onBlur={(e: React.SyntheticEvent<HTMLInputElement>) => this.handleBlur(e)}
                    type='text'
                    ref={(el: HTMLInputElement) => {
                        this.input = el;
                    }}
                    onChange={(e) => {
                        const needle = e.currentTarget.value;
                        let exactMatch: [string, string] | false = false;

                        const newChoices = this.props.choices.filter(([value, label]) => {
                            const lowerLabel = label.toLowerCase();
                            if (lowerLabel === needle.toLowerCase()) {
                                exactMatch = [value, label];
                            }
                            return lowerLabel.startsWith(needle.toLowerCase());
                        });

                        if (exactMatch) {
                            onChange(exactMatch[0]);
                        }

                        this.setState({
                            valuePresentation: exactMatch ? exactMatch[1] : needle,
                            choices: newChoices,
                            selectedValue: exactMatch ? exactMatch[0] : '',
                        });
                    }}
                    value={valuePresentation}
                    className={'MaterialTextInput--input' + (touched && error ? ' MaterialTextInput--input__has-error' : '')}
                    readOnly={this.props.disableSearch}
                    aria-label={ariaLabel}
                />
                <span
                    className='MaterialTextInput--select-chevron'
                    onClick={(e) => {
                        this.input.focus();
                    }}
                />
                <input value={input.value} type='hidden' />
                <span className='MaterialTextInput--bar' />
                <div className={'MaterialTextInput--select-wrapper' + (isOpen ? ' MaterialTextInput--select-wrapper__open' : '')}>
                    {choices.map(([value, label]) => (
                        <div
                            key={value}
                            className={
                                'MaterialTextInput--select-option' +
                                (selectedValue === value ? ' MaterialTextInput--select-option__active' : '')
                            }
                            onMouseDown={(e: React.SyntheticEvent<HTMLDivElement>) => {
                                this.setState(
                                    {
                                        valuePresentation: label,
                                        selectedValue: value,
                                    },
                                    () => onChange(value)
                                );
                            }}
                        >
                            {label}
                        </div>
                    ))}
                    {choices.length === 0 && <div className='MaterialTextInput--select-alert'>Nothing found</div>}
                </div>
                <label className={'MaterialTextInput--label' + (hasValue ? ' MaterialTextInput--label__active' : '')}>{label}</label>
                {touched && error && <span className='MaterialTextInput--error'>{error}</span>}
            </div>
        );
    }

    private tryGetPresentationForValue(value: string, dflt?: string) {
        const possibleLbls = this.props.choices.filter(([choiceVal, _]) => {
            return choiceVal === value;
        });
        return possibleLbls.length > 0 ? possibleLbls[0][1] : dflt || '';
    }
}

export default MaterialSelectInput;
