import * as React from 'react';
import cls from 'classnames';

import MessageCountBadge from './MessageCountBadge';
import ReviewCountBadge from './ReviewCountBadge';
import { HeaderContext, HeaderContextMenus } from './HeaderContext';

import styles from './Header.module.scss';
const { ACCOUNT } = HeaderContextMenus;

interface Props {
    userFirstName: string;
    unreadTotalCount: number;
}

// Account dropdown menu in header
const HeaderAccountMenu: React.SFC<React.PropsWithChildren<Props>> = (props) => {
    const { isContextMenuOpen, openContextMenu, closeContextMenu, headerItemClicked } = React.useContext(HeaderContext);

    return (
        <div className={cls(styles.dropdownWrapper)}>
            <a
                href='#'
                className={styles.navLink}
                onClick={() => {
                    isContextMenuOpen(ACCOUNT) ? closeContextMenu(ACCOUNT) : openContextMenu(ACCOUNT);
                    headerItemClicked('Menu');
                }}
            >
                <i className={cls('icon-hb-nx-user', styles.userIcon)} />
                <span data-dd-privacy="mask" data-dd-action-name="User Name">{props.userFirstName}</span> {!!props.unreadTotalCount && <MessageCountBadge count={props.unreadTotalCount} />}
                <i className={cls('icon-hb-arrowdown', styles.dropdownIcon)} />
            </a>

            <div className={cls(styles.dropdownMenu, { [styles.dropdownMenuOpen]: isContextMenuOpen(ACCOUNT) })}>
                <ul className='dropdown-menu'>{props.children}</ul>
            </div>
        </div>
    );
};

export default HeaderAccountMenu;
