import React from 'react';
import { FeatureFlagContext, FLAG_VENUE_PROFILE_PAGE_REVIEW } from '../../../providers/flag-context';
import styles from '../Header.module.scss';
import { HeaderTrackedLink } from '../MwebHeaderMenuBase';
import ReviewCountBadge from '../ReviewCountBadge';

interface Props {
    unreadReviewsCount?: number;
}
const ReviewsLink = (props: Props) => {
    const { flagEnabled } = React.useContext(FeatureFlagContext);

    if (!flagEnabled(FLAG_VENUE_PROFILE_PAGE_REVIEW)) {
        return null;
    }
    return (
        <div className={styles.mobileNavLinkWrapper}>
            <HeaderTrackedLink label='' url='/myaccount/reviews/' className={styles.mobileNavLink}>
                Reviews {!!props.unreadReviewsCount && <ReviewCountBadge count={props.unreadReviewsCount} />}
            </HeaderTrackedLink>
        </div>
    );
};

export default ReviewsLink;
