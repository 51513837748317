import * as React from 'react';
import Sticky from 'react-stickynode';
import cn from 'classnames';

import Header from './Header';
import { WSAppContext } from '../../providers/ws-app';
import { useWiderThan } from '../../utils/hooks';
import VenuesShoppingCartInquiryContent from './VenueShoppingCartInquiry/VenueShoppingCartInquiryContent';
import { FeatureFlagContext, FLAG_VENUE_PROFILE_PAGE_REVIEW } from '../../providers/flag-context';
import { reviewsCount } from './ReviewsCount';

import styles from './Header.module.scss';
import ReviewFormPageHeader from './ReviewFormPageHeader'; 

export interface Props {
    // Common props
    collapsed?: boolean; // If set to true, it will render the header, but with a height // TODO: Refactor Signup/signin modal logic out of header component
    // of 0px, this is useful if you still want to use the
    // signup/signin modal
    isWhiteStyle?: boolean; // If set to true logo and text are painter to white color
    // instead of black
    locationsMenuTrackingSource?: string; // Information about what page this header is on for tacking events

    readyForSplash?: boolean; // if set to true be ready for splash - do not render bottom border
    overlayStyle?: boolean; // if true, header is rendered on top of (z index) the page content
    hideTypeahead?: boolean; // whether or not to hide the typeahead
    isSticky?: boolean; // whether or not the header should be sticky

    showShoppingCart?: boolean; // whether or not to show shopping cart in header
    showShoppingCartExperience?: boolean; // whether or not to show shopping cart experience
}

/**
 * Header container that manages local header state. TODO: move this stuff into a general
 * header component that manages it's own state, the only reason this exists is for migration reasons
 * @param props header container props
 */
const HeaderContainer: React.FC<Props> = (props: Props) => {
    const { flagEnabled, isInitialized: isFlagsInitialized } = React.useContext(FeatureFlagContext);
    const [unreadReviewsCount, setUnreadReviewsCount] = React.useState<number>(0);

    // props
    const {
        collapsed,
        isWhiteStyle,
        locationsMenuTrackingSource,
        readyForSplash,
        overlayStyle,
        hideTypeahead,
        isSticky,
        showShoppingCart,
        showShoppingCartExperience,
    } = props;

    // state
    const { user, onCreateNewAccountSuccess } = React.useContext(WSAppContext);
    const isMobile = !useWiderThan(767);

    // fetch count of unread reviews
    React.useEffect(() => {
        if (isFlagsInitialized && flagEnabled(FLAG_VENUE_PROFILE_PAGE_REVIEW)) {
            reviewsCount(setUnreadReviewsCount);
        }
    }, [setUnreadReviewsCount, isFlagsInitialized, user]);

    return (
        <Sticky enabled={Boolean(isSticky && !isMobile)} innerClass={cn({ [styles.sticky]: isSticky })}>
            <Header
                locationsMenuTrackingSource={locationsMenuTrackingSource}
                unreadMessagesCount={user?.unreadMessagesCount || 0}
                unreadReviewsCount={unreadReviewsCount || 0}
                collapsed={collapsed}
                isWhiteStyle={isWhiteStyle || false}
                readyForSplash={readyForSplash || false}
                overlayStyle={overlayStyle}
                hideTypeahead={hideTypeahead}
                onCreateNewAccountSuccess={onCreateNewAccountSuccess}
                // shopping cart methods
                showShoppingCart={showShoppingCart}
                showShoppingCartExperience={showShoppingCartExperience}
            />
        </Sticky>
    );
};

export { HeaderContainer as Header };
export * from './HeaderContext';
export { VenuesShoppingCartInquiryContent as VenuesShoppingCartInquiryContent };
export { ReviewFormPageHeader };