import * as React from 'react';
import { useEffect, useState } from 'react';
import VenuesShoppingCartInquiryModal from './VenueShoppingCartInquiry/VenuesShoppingCartInquiryModal';
import { ShoppingCartButton, ShoppingCartContext, ShoppingCart, CartItem } from '../shopping-cart';
import { useWiderThan, useOutsideClickHandler, useWindowScroll } from '../../utils/hooks';
import { FeatureFlagContext } from '../../providers/flag-context/index';
import { VenuesShoppingCartExperience } from './VenuesShoppingCartExperience';
import { AnalyticsEvent, logEvent } from '@weddingspot/ws-analytics';

import ShoppingBagIcon from '@cvent/carina/components/Icon/ShoppingBag';

import styles from './VenuesShoppingCart.module.scss';

interface Props {
    withExperience?: boolean;
    onExperienceClose?: () => void;
    onExperienceRendered?: () => void;
    onExperienceNext?: () => void;
}

const VenuesShoppingCart = (props: Props) => {
    const { withExperience } = props;
    const [inquiryItems, setInquiryItems] = useState<CartItem[] | null>(null);
    const {
        cartOpen,
        setCartOpen,
        onCartOpen,
        onSubmitCTAClick,
        items,
        onItemRemoved,
        resetCart,
        onExperienceClose,
        onExperienceNext,
        onExperienceRendered,
        onOpenCartTouch,
    } = React.useContext(ShoppingCartContext);
    const shoppingCartContainerRef = React.useRef<HTMLDivElement | null>(null);
    const [sourceData, setSourceData] = React.useState<string | undefined>(undefined);
    const { flagEnabled } = React.useContext(FeatureFlagContext);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const sourceData = window.location.pathname.match('/venue/[0-9]+/.*') ? 'Venue Profile Page' : undefined;
            setSourceData(sourceData);
        }
    }, []);

    useEffect(() => {
        logEvent(AnalyticsEvent.EVTNM_SHOPPING_CART_TEXT_POPUP, {
            label: withExperience && flagEnabled('shopping_cart_help_text_pop_up') ? 'cart shown' : 'cart hidden',
        });
    }, []);

    // On click outisde of shopping cart, close it
    useOutsideClickHandler(shoppingCartContainerRef, () => setCartOpen(false));

    useEffect(() => {
        if (cartOpen) {
            onCartOpen && onCartOpen(sourceData);
        }
    }, [cartOpen]);

    const { scrollY } = useWindowScroll(false);

    const submitHandler = () => {
        setInquiryItems(null);
        resetCart();
    };

    return (
        <div className={styles.shoppingCartContainer} ref={shoppingCartContainerRef}>
            <VenuesShoppingCartInquiryModal
                items={inquiryItems}
                onClose={() => {
                    setInquiryItems(null);
                }}
                onSubmit={submitHandler}
            />
            <ShoppingCartButton
                itemDescriptor={(count: number) => (count < 1 ? 'Venue list' : `${count} venues`)}
                carinaIcon={ShoppingBagIcon}
                isMobile={!useWiderThan(767)}
                isFixed={scrollY > 66}
                experienceContent={
                    withExperience && flagEnabled('shopping_cart_help_text_pop_up')
                        ? (closeFlyout: () => void) => (
                              <VenuesShoppingCartExperience
                                  onClose={closeFlyout}
                                  onCloseButton={onExperienceClose}
                                  onNext={onExperienceNext}
                                  onRendered={onExperienceRendered}
                              />
                          )
                        : undefined
                }
            />
            {cartOpen && (
                <div className={styles.shoppingCartList} onMouseOver={onOpenCartTouch}>
                    <ShoppingCart
                        itemsMax={10}
                        title={'Venue List'}
                        subtitle={"Contact all the wedding venues you're interested in at the same time. "}
                        recommendationsTitle={'Here are some more places you may love.'}
                        addHelpText={(count: number) => `Add up to ${count} venues`}
                        addCTAText={'Find more venues'}
                        onClickAddCTA={() => {
                            window.location.pathname = '/wedding-venues';
                            return Promise.resolve(false);
                        }}
                        submitCTAText={'Message venues'}
                        submitCTASubtitle={'Tell venues about your wedding. Venues will respond with a personalized quote. '}
                        onClickSubmitCTA={() => {
                            if (flagEnabled('venues_new_checkout_flow')) {
                                window.location.pathname = '/checkout/';
                            } else {
                                setInquiryItems(items);
                                onSubmitCTAClick && onSubmitCTAClick(items, sourceData);
                            }

                            return Promise.resolve(false);
                        }}
                        onRemoveItem={(id) => {
                            onItemRemoved && onItemRemoved(id);
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default VenuesShoppingCart;
