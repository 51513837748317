import * as React from 'react';
import logoUrl from './assets/logo.png';
import styles from './Header.module.scss';
const ReviewFormPageHeader = () => {
    return (
        <div className={styles.reviewFormPageHeader}>
            <a
                target='_blank'
                href='/'
                className={styles.logoLink}
                style={{display:'inline-block'}}
            >
                <img width='111px' height='56px' className={styles.regularLogo} src={logoUrl?.src || logoUrl} alt='Wedding Spot logo'/>
            </a>
        </div>
    );
}
export default ReviewFormPageHeader;
