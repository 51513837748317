import * as React from 'react';

import MwebHeaderMenuAuth from './MwebHeaderMenuAuth';
import MessageCountBadge from './MessageCountBadge';
import { HeaderTrackedLink } from './MwebHeaderMenuBase';
import styles from './Header.module.scss';
import ReviewsLink from './Links/ReviewsLink';

export default class MwebHeaderMenuVendor extends MwebHeaderMenuAuth {
    renderMenuItemsTop() {
        return (
            <>
                <div className={styles.mobileNavLinkWrapper}>
                    <HeaderTrackedLink label='' url='/myaccount/messages/' className={styles.mobileNavLink}>
                        Messages {!!this.props.unreadMessageCount && <MessageCountBadge count={this.props.unreadMessageCount} />}
                    </HeaderTrackedLink>
                </div>
                <ReviewsLink unreadReviewsCount={this.props.unreadReviewsCount} />
                <div className={styles.mobileNavLinkWrapper}>
                    <HeaderTrackedLink label='My Leads' url='/myaccount/leads/' className={styles.mobileNavLink} />
                </div>
                <div className={styles.mobileNavLinkWrapper}>
                    <HeaderTrackedLink label='Venue Information' url='/myaccount/edit-venue-details/' className={styles.mobileNavLink} />
                </div>
            </>
        );
    }
}
