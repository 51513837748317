import { stringify } from 'querystring';
import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';
const styles = require('./scss/captcha-checkbox.module.scss');
import './scss/captcha-checkbox.module.scss';

export const CaptchaCheckbox = (props: WrappedFieldProps & {id: string} & {ariaLabel?: string}) => {
    const {
        input: { onFocus, onBlur, ...input },
        meta: { error, submitFailed },
    } = props;

    return (
        <div >
            <input id={props.id} {...input} type={'checkbox'} hidden={true} aria-label={props.ariaLabel}/>
            {error && submitFailed && <div className={styles.CaptchaCheckbox__error + ' CaptchaCheckbox__error'} >{error}</div>}
        </div>
    );
};

export default CaptchaCheckbox;
