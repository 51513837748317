import * as React from 'react';
import cls from 'classnames';

import MwebHeaderMenuBase, { HeaderTrackedLink } from './MwebHeaderMenuBase';

import styles from './Header.module.scss';
const SlideDown = require('react-slidedown').SlideDown;
import 'react-slidedown/lib/slidedown.css';
import { HeaderSubmenus } from './HeaderContext';
import { TrackedLogoutLink } from './TrackedLogoutLink';

const { MYACCOUNT } = HeaderSubmenus;

interface Props {
    unreadMessageCount: number;
    unreadReviewsCount: number;
}

export default class MwebHeaderMenuAuth<T = {}> extends MwebHeaderMenuBase<T & Props> {
    renderMenuItems() {
        const { headerItemClicked, isSubmenuOpen } = this.context;

        return (
            <div>
                {this.renderMenuItemsTop()}
                <div className={styles.mobileNavLinkWrapper}>
                    <div className={styles.mobileNavLink}>
                        <div className={styles.mobileNavDivider} />
                    </div>
                </div>
                {this.renderBrowseVenuesNav()}
                <div className={styles.mobileNavLinkWrapper}>
                    <HeaderTrackedLink label='Hotel Room Blocks' url='/hotels/' className={styles.mobileNavLink} />
                </div>
                <div className={styles.mobileNavLinkWrapper}>
                    <HeaderTrackedLink label='Blog' url='/blog/' className={styles.mobileNavLink} />
                </div>
                <div className={styles.mobileNavLinkWrapper}>
                    <HeaderTrackedLink label='Help' url='/myaccount/help/' className={styles.mobileNavLink} />
                </div>
                <div>
                    <a
                        href='#'
                        className={styles.mobileNavLink}
                        onClick={(e) => {
                            e.preventDefault();
                            this.toggleSubmenu(MYACCOUNT);
                            headerItemClicked('Account Settings');
                        }}
                    >
                        Account Settings <i className={cls('icon-hb-arrowdown', styles.mobileNavCollapseIcon)} />
                    </a>

                    <SlideDown>
                        {isSubmenuOpen(MYACCOUNT) && (
                            <div className={styles.mobileNavSubmenu}>
                                <HeaderTrackedLink label='Contact Info' url='/myaccount/contactinfo/' className={styles.mobileNavLink} />
                                <HeaderTrackedLink label='Password' url='/myaccount/change-password/' className={styles.mobileNavLink} />
                                <HeaderTrackedLink
                                    label='Messaging Preferences'
                                    url='/myaccount/email-preferences/'
                                    className={styles.mobileNavLink}
                                />
                            </div>
                        )}
                    </SlideDown>
                </div>
                <TrackedLogoutLink className={styles.mobileNavButtonLink} />
            </div>
        );
    }

    renderMenuItemsTop(): React.ReactNode | undefined {
        return undefined; // Implemented by inheritor
    }
}
