import * as React from 'react';
import { ShoppingCardExperience } from '../shopping-cart';
import { getAssetPrefix } from '../../utils/url';
import { useWiderThan } from '../../utils/hooks';

const initialScreenWebpImage = `${getAssetPrefix()}/images/venueShoppingCardExperience/initial_frame.webp`;
const initialScreenWebpImageMob = `${getAssetPrefix()}/images/venueShoppingCardExperience/initial_frame_mobile.webp`;
const initialScreenJpegImage = `${getAssetPrefix()}/images/venueShoppingCardExperience/initial_frame.webp`;
const initialScreenJpegImageMob = `${getAssetPrefix()}/images/venueShoppingCardExperience/initial_frame_mobile.webp`;
const secondSlideVideoMp4 = `${getAssetPrefix()}/videos/venueShoppingCardExperience/sequence_2.mp4`;
const secondSlideVideoMp4Mob = `${getAssetPrefix()}/videos/venueShoppingCardExperience/mobile/sequence_2.mp4`;
const thirdSlideVideoMp4 = `${getAssetPrefix()}/videos/venueShoppingCardExperience/sequence_3.mp4`;
const thirdSlideVideoMp4Mob = `${getAssetPrefix()}/videos/venueShoppingCardExperience/mobile/sequence_3.mp4`;
const fourthSlideVideoMp4 = `${getAssetPrefix()}/videos/venueShoppingCardExperience/sequence_4.mp4`;
const fourthSlideVideoMp4Mob = `${getAssetPrefix()}/videos/venueShoppingCardExperience/mobile/sequence_4.mp4`;
const secondSlideVideoWebM = `${getAssetPrefix()}/videos/venueShoppingCardExperience/sequence_2.webm`;
const secondSlideVideoWebMMob = `${getAssetPrefix()}/videos/venueShoppingCardExperience/mobile/sequence_2.webm`;
const thirdSlideVideoWebM = `${getAssetPrefix()}/videos/venueShoppingCardExperience/sequence_3.webm`;
const thirdSlideVideoWebMMob = `${getAssetPrefix()}/videos/venueShoppingCardExperience/mobile/sequence_3.webm`;
const fourthSlideVideoWebM = `${getAssetPrefix()}/videos/venueShoppingCardExperience/sequence_4.webm`;
const fourthSlideVideoWebMMob = `${getAssetPrefix()}/videos/venueShoppingCardExperience/mobile/sequence_4.webm`;

interface Props {
    onClose?: () => void;
    onCloseButton?: () => void;
    onRendered?: () => void;
    onNext?: () => void;
}

export const VenuesShoppingCartExperience = (props: Props) => {
    const { onClose, onCloseButton, onRendered, onNext } = props;
    const isSmallScreen = !useWiderThan(769);

    return (
        <ShoppingCardExperience
            onClose={onClose}
            onCloseButton={onCloseButton}
            onRendered={onRendered}
            onNext={onNext}
            slides={[
                {
                    imageSet: {
                        webp: isSmallScreen ? initialScreenWebpImageMob : initialScreenWebpImage,
                        jpeg: isSmallScreen ? initialScreenJpegImageMob : initialScreenJpegImage,
                        fallbackImage: initialScreenJpegImage,
                    },
                    title: 'A new and easy way to contact venues!',
                    description: "Contact all the wedding venues you're interested in at the same time.",
                },
                {
                    videoMp4: isSmallScreen ? secondSlideVideoMp4Mob : secondSlideVideoMp4,
                    videoWebM: isSmallScreen ? secondSlideVideoWebMMob : secondSlideVideoWebM,
                    title: 'Step One',
                    description: 'Search for your perfect wedding venues.',
                },
                {
                    videoMp4: isSmallScreen ? thirdSlideVideoMp4Mob : thirdSlideVideoMp4,
                    videoWebM: isSmallScreen ? thirdSlideVideoWebMMob : thirdSlideVideoWebM,
                    title: 'Step Two',
                    description: 'Click "Add to list" when you find the venues you fall in love with.',
                },
                {
                    videoMp4: isSmallScreen ? fourthSlideVideoMp4Mob : fourthSlideVideoMp4,
                    videoWebM: isSmallScreen ? fourthSlideVideoWebMMob : fourthSlideVideoWebM,
                    title: 'Step Three',
                    description: 'Click "Message venues" to send your details to all your venues at once!',
                },
            ]}
        />
    );
};
