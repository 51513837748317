import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';

import './scss/material-checkbox-input.module.scss';

export interface OwnProps {
    label: string;
}
export type MaterialCheckboxProps = WrappedFieldProps & OwnProps;

export class MaterialCheckbox extends React.Component<MaterialCheckboxProps, {}> {
    render() {
        const {
            input,
            label,
            meta: { error, touched },
        } = this.props;

        return (
            <div className='MaterialCheckboxInput nx-checkbox nx-checkbox--large'>
                <input {...input} type='checkbox' id={`id_${input.name}`} className='MaterialCheckboxInput--input' checked={input.value} />
                <label className='MaterialCheckboxInput--label' htmlFor={`id_${input.name}`}>
                    {label}
                </label>
                {touched && error && <span className='MaterialCheckboxInput--error'>{error}</span>}
            </div>
        );
    }
}

export default MaterialCheckbox;
