import React, { useState } from 'react';
import Button from '@cvent/carina/components/Button';
import CheckoutVenueRecommendationsCardsList from '../../checkout/Cards/CardsList';
import { CheckoutVenueRecommendationsCardInterface } from '../../checkout/Cards/Card';

import styles from './VenuesShoppingCartInquiryRecommendations.module.scss';
import { Appointments, VendorsApi } from '@weddingspot/ws-api-client';
import { VenuesShoppingCartModalStage } from './VenuesShoppingCartInquiryModal.types';
import SpinnerOverlay from '../../spinner-overlay';
import { AnalyticsEvent, EventParams, InquiryType, logEvent } from '@weddingspot/ws-analytics';

interface VenuesShoppingCartInquiryRecommendationsListInterface {
    recommendations: CheckoutVenueRecommendationsCardInterface[];
    changeIsChecked: (id: number) => void;
    setHotelRecommendations: (value: CheckoutVenueRecommendationsCardInterface[]) => void;
    setCurrentStage: (x: number) => void;
    submittedInquiryData: Appointments.MassInquiryRequestParams | null;
    exploreHotelsUrl?: (string | undefined);
}

const VenuesShoppingCartInquiryRecommendations = ({
    recommendations,
    changeIsChecked,
    setHotelRecommendations,
    setCurrentStage,
    submittedInquiryData,
    exploreHotelsUrl,
}: VenuesShoppingCartInquiryRecommendationsListInterface) => {
    const [isLoading, setIsLoading] = useState(false);
    const getHotelsRecommendationsStep = () => {
        const postHotelsData = {
            ws_seed_ids: recommendations.map((item) => Number(item.id)),
            limit: 6,
        };
        setIsLoading(true);
        VendorsApi.getHotelRecommendations(postHotelsData).then((hotels) => {
            setHotelRecommendations(
                hotels.map((item) => ({
                    title: item.name,
                    location: `${item.city}, ${item.state}`,
                    image: item.image,
                    isChecked: true,
                    checkedLabel: 'Send message',
                    id: item.csn_id,
                    type: item.type,
                }))
            );
            setIsLoading(false);
            setCurrentStage(hotels.length ? VenuesShoppingCartModalStage.GUEST_ROOMS : VenuesShoppingCartModalStage.SUCCESS);
        });
    };

    const submitHandler = async () => {
        setIsLoading(true);
        const submitData = {
            ...submittedInquiryData,
            vendor_ids:
                recommendations
                    .filter((item) => item.isChecked)
                    .map((item: CheckoutVenueRecommendationsCardInterface) => Number(item.id)) || [],
        } as Appointments.MassInquiryRequestParams;
        await VendorsApi.sendMassInquiries(submitData);
        getHotelsRecommendationsStep();
    };

    return (
        <SpinnerOverlay
            spinnerProps={{
                spinnerColor: '#881155',
                spinnerDiameter: 40,
            }}
            isSpinning={isLoading}
        >
            <div className={styles.subHeaderMessage}>
                Inquiry sent! Our recommendations are based on site usage and have helped other couples find their dream venue. And now it’s
                your turn to find the perfect match. Give it a try!
            </div>
            <div>
               <a href={exploreHotelsUrl} target='_blank' className={styles.exploreHotelsCTA}>Start exploring hotel rooms</a>
            </div>
            <div className={styles.body}>
                <CheckoutVenueRecommendationsCardsList list={recommendations} changeIsChecked={changeIsChecked} />
                <div className={styles.buttons}>
                    <Button
                        text='Skip'
                        onClick={() => {
                            setCurrentStage(VenuesShoppingCartModalStage.SUCCESS);
                        }}
                        appearance={'lined'}
                        accessibilityLabel={'Skip'}
                    />

                    {recommendations.some((item) => item.isChecked) && (
                        <Button text='Send' onClick={submitHandler} appearance={'filled'} accessibilityLabel={'Send'} />
                    )}
                </div>
            </div>
        </SpinnerOverlay>
    );
};

export default VenuesShoppingCartInquiryRecommendations;
