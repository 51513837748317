import * as React from 'react';

import MwebHeaderMenuAuth from './MwebHeaderMenuAuth';
import MessageCountBadge from './MessageCountBadge';
import { HeaderTrackedLink } from './MwebHeaderMenuBase';

import styles from './Header.module.scss';

export default class MwebHeaderMenuCouple extends MwebHeaderMenuAuth {
    renderMenuItemsTop() {
        return (
            <>
                <div className={styles.mobileNavLinkWrapper} data-testid='MwebHeaderMenuCouple--items'>
                    <HeaderTrackedLink label='Messages' url='/myaccount/messages/' className={styles.mobileNavLink}>
                        Messages {!!this.props.unreadMessageCount && <MessageCountBadge count={this.props.unreadMessageCount} />}
                    </HeaderTrackedLink>
                </div>
                <div className={styles.mobileNavLinkWrapper}>
                    <HeaderTrackedLink label='Favorites' url='/myaccount/favorites/' className={styles.mobileNavLink} />
                </div>
                <div className={styles.mobileNavLinkWrapper}>
                    <HeaderTrackedLink label='Spot Estimates' url='/myaccount/spot-estimates/' className={styles.mobileNavLink} />
                </div>
                <div className={styles.mobileNavLinkWrapper}>
                    <HeaderTrackedLink label='Inquiries' url='/myaccount/appointments/' className={styles.mobileNavLink} />
                </div>
            </>
        );
    }
}
