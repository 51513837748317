import * as React from 'react';
import cls from 'classnames';

import SubheaderLinks from '../subheader';
import { SubheaderLinksProps } from '../subheader/SubHeader';
import { WSAppContext } from '../../providers/ws-app';
import { HeaderContext, HeaderContextMenus } from './HeaderContext';

import styles from './Header.module.scss';
const { SUBHEADER_LINKS } = HeaderContextMenus;

export interface HeaderRightLinksUnauthProps extends SubheaderLinksProps {}

// Main links component for auth'd users
const HeaderRightLinksUnauth: React.SFC<HeaderRightLinksUnauthProps> = (props) => {
    const { openAuthModal } = React.useContext(WSAppContext);
    const { headerItemClicked, isContextMenuOpen, openContextMenu, closeContextMenu } = React.useContext(HeaderContext);

    return (
        <div className={cls(styles.navWrapper, styles.hiddenMobile)} data-testid='HeaderRightLinks'>
            <a href='/hotels/' target='_blank' className={styles.navLink} onClick={() => headerItemClicked('Hotel Room Blocks')}>
                Hotel Room Blocks
            </a>
            <div
                className={styles.navLink}
                onClick={() => {
                    headerItemClicked('Browse Venues');
                    isContextMenuOpen(SUBHEADER_LINKS) ? closeContextMenu(SUBHEADER_LINKS) : openContextMenu(SUBHEADER_LINKS);
                }}
            >
                Browse Venues
            </div>
            <a href='/blog/' target='_blank' className={styles.navLink} onClick={() => headerItemClicked('Blog')}>
                Blog
            </a>
            <a
                href='#'
                className={styles.navLink}
                onClick={(e: React.SyntheticEvent<HTMLAnchorElement>) => {
                    headerItemClicked('Sign In');
                    openAuthModal();
                }}
            >
                Sign In
            </a>
            <div className={styles.headerItemSeparator} />
            <a href='/vendor-signup/' className={styles.navLink} onClick={() => headerItemClicked('Join as a Venue')}>
                Join as a Venue
            </a>
            <div
                className={cls(styles.subheaderLinksWrapper, { [styles.subheaderLinksWrapperShowing]: isContextMenuOpen(SUBHEADER_LINKS) })}
                data-testid='SubheaderLinks'
            >
                <SubheaderLinks curMarket={props.curMarket} subheaderLinks={props.subheaderLinks} />
            </div>
        </div>
    );
};

export default HeaderRightLinksUnauth;
