import * as React from 'react';
import cls from 'classnames';

const styles = require('./Header.module.scss');

// Unread message count badge that shows up in a couple places
const ReviewCountBadge: React.SFC<{ count: number }> = (props) => {
    return <span className={cls(styles.reviewsBadge)}>{props.count}</span>;
};

export default ReviewCountBadge;
