import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import './scss/material-text-input.module.scss';
import './scss/material-datepicker-input.module.scss';
import { format } from 'date-fns';
import { addMonths } from '../../utils/date';

export interface OwnProps {
    label?: string;
    placeholder?: string;
    dateFormat?: string;
    customInput?: React.ReactNode;
    minDate?: Date;
    maxDate?: Date;
    excludeDates?: Date[];
    filterDate?: (m: Date) => boolean;
    handleOnBlur?: (value: string) => void;
    disabled?: boolean;
}
export type MaterialDatePickerInputProps = WrappedFieldProps & OwnProps;

export const DEFAULT_DATE_FORMAT = 'MMMM d, yyyy';

export class MaterialDatePickerInput extends React.Component<MaterialDatePickerInputProps, {}> {
    constructor(props: MaterialDatePickerInputProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(date: Date) {
        if (date !== null) {
            // Null date passed as an input value to state crash somehow.
            // This is a workaround.
            this.props.input.onChange(format(new Date(date), DEFAULT_DATE_FORMAT));
        }
    }

    render() {
        const {
            label,
            input,
            meta: { initial, error, touched },
            placeholder,
            dateFormat,
            customInput,
            minDate,
            maxDate,
            excludeDates,
            filterDate,
            disabled,
        } = this.props;

        const selected = input.value ? new Date(input.value) : !!initial ? new Date(initial) : null;
        const hasValue = !!initial || input.value.length !== 0;
        const _dateFormat = dateFormat || DEFAULT_DATE_FORMAT;
        return (
            <div className='MaterialTextInput MaterialDatePickerInput'>
                <DatePicker
                    {...input}
                    className={'MaterialTextInput--input' + (touched && error ? ' MaterialTextInput--input__has-error' : '')}
                    popperPlacement='bottom-start'
                    disabledKeyboardNavigation={true}
                    dateFormat={_dateFormat}
                    placeholderText={placeholder}
                    onChange={this.handleChange}
                    selected={selected}
                    customInput={customInput}
                    minDate={minDate}
                    maxDate={maxDate}
                    excludeDates={excludeDates}
                    filterDate={filterDate}
                    disabled={disabled}
                    autoComplete={'invalidvalue'}
                    {...{
                        /* These props haven't been added to to types lib yet... */ nextMonthButtonLabel: '',
                        previousMonthButtonLabel: '',
                        openToDate: addMonths(new Date(), 6),
                    }}
                />

                <div className='MaterialTextInput--bar' />
                <label className={'MaterialTextInput--label' + (hasValue ? ' MaterialTextInput--label__active' : '')}>{label}</label>
                {touched && error && <div className='MaterialTextInput--error'>{error}</div>}
            </div>
        );
    }
}

export default MaterialDatePickerInput;
