import * as React from 'react';
import { formatCventImage, isCventImage } from '../../utils/imageUtils';

/**
 * CventImage props
 *
 * @param src - Original image url.
 * @param alt - Fallback string.
 * @param className - Additional styling.
 * @param resize - Resizing while preserving aspect ratio. Example url query arg `400x300`.
 *                 If not provided original image will be used by default .
 * @param strict - Strict resizing d=\<width\>x\<height\>. If true then strict size will be used else
 *                 will preserve aspect ratio by width. Default is `false`
 * @param quality - q=\<quality_percent\> where quality_percent is an integer between 1 and 100 (no fractions)
 */
interface CventImageProps {
    src: string;
    alt?: string;
    className?: string;
    hidden?: boolean;
    onLoad?: () => void;
    itemProp?: string;
    resize?: string;
    strict?: boolean;
    quality?: number;
}

// Ordered list of image format preferences. jpeg is the final fallback
const IMAGE_FORMAT_STACK = ['webp'];

const addImageFormat = (url: string, format: string) => {
    try {
        const _url = new URL(url);
        _url.searchParams.set('f', format);
        return _url.href;
    } catch {
        return url;
    }
};

const CventImage = (props: CventImageProps) => {
    const { src, className, alt, resize, strict, quality } = props;

    const fallbackImg = <img className={className} src={src} alt={alt} onLoad={props.onLoad} />;

    if (!src || !isCventImage(src)) {
        return fallbackImg;
    }

    const srcUrl = formatCventImage({
        src: src,
        resize,
        strict,
        quality,
    });

    return (
        <picture>
            {IMAGE_FORMAT_STACK.map((f, idx) => (
                <source key={idx} srcSet={addImageFormat(srcUrl, f)} type={`image/${f}`} />
            ))}
            <img
                className={props.className}
                src={addImageFormat(srcUrl, 'jpeg')}
                alt={props.alt}
                hidden={props.hidden}
                onLoad={props.onLoad}
                itemProp={props.itemProp}
            />
        </picture>
    );
};

export default CventImage;
