import * as React from 'react';
import { Appointments, VendorsApi, Venues, CSNServiceApi, CSNGeographicPointFilter, CSNQueryParserResult } from '@weddingspot/ws-api-client';

import VenuesShoppingCartModalList from '../VenuesShoppingCartModalList';
import { SubmissionError } from 'redux-form';
import { CartItem, ShoppingCartContext } from '../../shopping-cart';
import {
    InquiryFormContainer,
    InquiryWidgetSuccess,
    FormData,
    Step1Data,
    DWTInquiryFormStep2,
    DWTInquiryFormStep1,
} from '../../appointments';
import { format } from 'date-fns';

import styles from './VenueShoppingCartInquryContent.module.scss';
import { VenuesShoppingCartModalStage } from './VenuesShoppingCartInquiryModal.types';
import { useState } from 'react';
import { StorableDataKeys } from '../../../utils/storage';
import { CheckoutVenueRecommendationsCardInterface } from '../../checkout/Cards/Card';
import VenuesShoppingCartInquiryRecommendations from './VenuesShoppingCartInquiryRecommendations';
import VenuesShoppingCartInquiryHotels from './VenuesShoppingCartInquiryHotels/VenuesShoppingCartInquiryHotels';
import { LocalStorage } from '../../..';
import { Base64 } from 'js-base64';
import { getAssetPrefix } from '../../../utils/url';
import { SRP_VIEWS, CONTRACT_PACKAGE } from '../../../constants';

const DWLogo2023 = `${getAssetPrefix()}/images/integrations/destination_weddings/DWLogo2023.png`;

interface VenuesShoppingCartInquiryContentProps {
    currentStage: VenuesShoppingCartModalStage;
    setCurrentStage: (x: number) => void;
    setInquiryFormSubmitted: (x: boolean) => void;
    closeModal: () => void;
    resetModal: () => void;
    onFormSubmit?: () => void;
    items: CartItem[] | null;
    isAuthenticated: boolean;
}

interface FullInquiryFromData extends FormData {
    preferred_wedding_date?: string;
}

const VenuesShoppingCartInquiryContent = (props: VenuesShoppingCartInquiryContentProps) => {
    const { currentStage, setCurrentStage, onFormSubmit, closeModal, items, isAuthenticated, resetModal } = props;

    const { onSuccessfulOutreach } = React.useContext(ShoppingCartContext);
    const [recommendations, setRecommendations] = useState<CheckoutVenueRecommendationsCardInterface[]>([]);
    const [hotelRecommendations, setHotelRecommendations] = useState<CheckoutVenueRecommendationsCardInterface[]>([]);
    const [submittedInquiryData, setSubmittedInquiryData] = useState<Appointments.MassInquiryRequestParams | null>(null);
    const [firstStepData, setFirstStepData] = React.useState<Step1Data | null>(null);
    const [isSecondStep, setIsSecondStep] = React.useState<boolean>(false);
    const [exploreHotelsUrl, setExploreHotelsUrl] = React.useState<string>('');
    const [captchaWidgetId, setCaptchaWidgetId] = React.useState<string | undefined>(undefined);

    const location = window?.location?.pathname || 'unknown';

    const sourceData = location.match('/venue/[0-9]+/.*')
        ? 'modal from profile page'
        : location.startsWith('/pricing/')
        ? 'modal from spot estimate page'
        : location;

    const getExploreHotelsURL = async () => {
        let redirectUrl: string = `/hotels/search/`;

        if (props.items && props.items.length > 1) {
            return redirectUrl;
        }

        const address = props.items?.map((item) => item.address);
        const city = props.items?.map((item) => item.city);
        const state = props.items?.map((item) => item.state);
        const zipCode = props.items?.map((item) => item.zipCode);

        const queryParserRes = await CSNServiceApi.queryParser({
            queryString: `${address}, ${city}, ${state} ${zipCode}`,
        });
        const geoFilter = getGeoFilter(queryParserRes);

        const searchState = {
            q: queryParserRes.formattedSearchText,
            f: geoFilter,
            b: queryParserRes.boundingBoxFilter.boundingBox,
        };
        redirectUrl = `/hotels/search/?state=${Base64.btoa(JSON.stringify(searchState))}`;
        return redirectUrl;
    }

    const getGeoFilter = (obj: CSNQueryParserResult): CSNGeographicPointFilter => {
        /*
        CSNQueryParser checking if a search term matches a pre-defined GeoJSON polygon stored in Elasticsearch.
        if the Google Suggestion geographic type matches one of the polygon types(
            * US states,
            * Canadian provinces,
            * the Caribbean
        ),
        CSNQueryParser provides 'POLYGON' filter
        */
        const polygon = (obj?.additionalFilters || []).find((i) => i.type === 'POLYGON');
        if (polygon) {
            return polygon;
        }
        return {
            ...obj.geographicPointFilter,
            maximum: 25,
            measurementUnit: 'MILES',
        };
    };

    const submitFormHandler = async (data: FullInquiryFromData) => {
        if (props.items) {
            let visitedVenues = null;
            try {
                visitedVenues = localStorage.getItem(StorableDataKeys.VISITED_VENUES);
            } catch (e) {
                console.error('Something wrong with local storage', e);
            }

            let postData: Venues.VenueRecommendationsQueryParams = {
                seed_ids: props.items.map((item: CartItem) => Number(item.id)),
                count: 6,
            };

            if (visitedVenues) {
                postData.exclude_ids = JSON.parse(visitedVenues);
            }

            var venues_analytics = {};
            var venues_contract_package_count = {};
            Object.values(CONTRACT_PACKAGE).forEach((value: string) => {
                venues_contract_package_count[value] = 0;
            });
            var venues_srp_view_contract_package_count = {
                [SRP_VIEWS.LIST]: { ...venues_contract_package_count },
                [SRP_VIEWS.GRID]: { ...venues_contract_package_count },
            };

            items?.forEach((item: CartItem) => {
                const { srpView, contractPackage } = item;
                if (srpView && contractPackage) {
                    const srpViewKey = `VenueCount_${srpView}`;
                    venues_analytics[srpViewKey] = (venues_analytics[srpViewKey] || 0) + 1;
                    venues_srp_view_contract_package_count[srpView][contractPackage]++;
                    if (contractPackage !== CONTRACT_PACKAGE.FREE) {
                        const srpViewPaidKey = `${srpViewKey}_paid`;
                        venues_analytics[srpViewPaidKey] = (venues_analytics[srpViewPaidKey] || 0) + 1;
                    }
                }
            });

            const contractPackage = JSON.stringify(venues_srp_view_contract_package_count).replace(/["{}]/g, '');
            venues_analytics['ContractPackage'] = contractPackage;

            const {captcha_widget_id, ...submit_data} = data;

            let submitData = {
                ...submit_data,
                contact_reason: null,
                outreach_type: Appointments.OutreachTypes.WEDDING_FLOW,
                tos_shown: !isAuthenticated,
                vendor_ids: items?.map((item: CartItem) => Number(item.id)) || [],
                creation_source: 'shopping-cart' as const,
            };

            if (!submitData.preferred_date_1) {
                delete submitData.preferred_date_1;
            } else {
                submitData.preferred_date_1 = format(new Date(submitData.preferred_date_1), 'yyyy-MM-dd');
            }
            if (submitData.preferred_wedding_date) {
                submitData.preferred_wedding_date = format(new Date(submitData.preferred_wedding_date), 'yyyy-MM-dd');
            }
            if (isSecondStep) {
                // @ts-ignore
                submitData.grecaptcha_response = grecaptcha.getResponse(captcha_widget_id || captchaWidgetId);
            } else {
                // @ts-ignore
                submitData.grecaptcha_response = grecaptcha.getResponse(captcha_widget_id || captchaWidgetId);
            }
            setSubmittedInquiryData(submitData);
            try {
                await VendorsApi.sendMassInquiries(submitData);
                const responses = await VendorsApi.getVenueRecommendations(postData);
                
                const recommendations = responses.map((item: Venues.VenueRecommendation) => ({
                    title: item.name,
                    location: `${item.city}, ${item.state}`,
                    image: item.thumbnail_url,
                    isChecked: true,
                    checkedLabel: 'Send message',
                    id: item.id,
                }));
                setRecommendations(recommendations);
                !!onFormSubmit && onFormSubmit();
                props.setInquiryFormSubmitted(true);
                setCurrentStage(VenuesShoppingCartModalStage.RECOMMENDATIONS);

                submitData['venues_analytics'] = venues_analytics;

                onSuccessfulOutreach && onSuccessfulOutreach(submitData, sourceData);
            } catch (e) {
                new SubmissionError({ _error: 'Inquiry submission failed. Please try again later' });
            }
            
            setExploreHotelsUrl(await getExploreHotelsURL());
        }
    };

    const onStep2 = (values: Step1Data) => {
        setFirstStepData(values);
        setIsSecondStep(true);
    };

    const isDestinationWeddingExists = props.items?.find((value: CartItem) => value.isDestination);

    const renderForm = () => (
        <>
            <VenuesShoppingCartModalList items={items || []} />
            <InquiryFormContainer
                handleClickModalClose={closeModal}
                hidePhoneNumberField={true}
                hideReasonForOutreachField={true}
                customSubmitHandler={submitFormHandler}
            />
        </>
    );

    const changeIsChecked = React.useCallback(
        (id: number) => {
            setRecommendations(
                recommendations.map((item: CheckoutVenueRecommendationsCardInterface) => {
                    if (item.id === id) {
                        item.isChecked = !item.isChecked;
                    }
                    return item;
                })
            );
        },
        [recommendations]
    );

    const renderFormDW = () => (
        <>
            <VenuesShoppingCartModalList items={items || []} />
            {isSecondStep && firstStepData ? (
                <DWTInquiryFormStep2
                    initialValues={firstStepData}
                    attributionLogo={DWLogo2023}
                    onSubmit={submitFormHandler}
                    handleBackClick={() => {
                        setIsSecondStep(false);
                    }}
                    setCaptchaWidgetId={(captcha_widget_id) => {
                        setCaptchaWidgetId(captcha_widget_id)
                    }}
                />
            ) : (
                <DWTInquiryFormStep1
                    initialValues={firstStepData || undefined}
                    userDataLoaded={true}
                    hasNextStep={true}
                    requireGuestCount={false}
                    clearDatesFlexible={() => {}}
                    clearPreferredDates={() => {}}
                    onSubmit={onStep2}
                    showSignupFields={!isAuthenticated}
                    attributionLogo={DWLogo2023}
                />
            )}
        </>
    );

    const renderSuccess = () => <InquiryWidgetSuccess handleOnButtonClick={resetModal} />;

    const renderMain = () => {
        if (currentStage === VenuesShoppingCartModalStage.SUCCESS) {
            return renderSuccess();
        } else if (currentStage === VenuesShoppingCartModalStage.INQUIRY_FORM && isDestinationWeddingExists) {
            return renderFormDW();
        } else if (currentStage === VenuesShoppingCartModalStage.INQUIRY_FORM) {
            return renderForm();
        } else if (currentStage === VenuesShoppingCartModalStage.RECOMMENDATIONS) {
            return (
                <VenuesShoppingCartInquiryRecommendations
                    recommendations={recommendations}
                    changeIsChecked={changeIsChecked}
                    setHotelRecommendations={setHotelRecommendations}
                    setCurrentStage={setCurrentStage}
                    submittedInquiryData={submittedInquiryData}
                    exploreHotelsUrl={exploreHotelsUrl}
                />
            );
        } else if (currentStage === VenuesShoppingCartModalStage.GUEST_ROOMS) {
            return (
                <VenuesShoppingCartInquiryHotels
                    hotelRecommendations={hotelRecommendations}
                    setHotelRecommendations={setHotelRecommendations}
                    setCurrentStage={setCurrentStage}
                    submittedInquiryData={submittedInquiryData}
                />
            );
        }
        return <></>;
    };

    return <div className={`InquiryModalContent ${styles.ShoppingCartInquiry}`}>{renderMain()}</div>;
};

export default VenuesShoppingCartInquiryContent;
