import * as React from 'react';
import { TypeaheadContext, emtpyContext, TypeaheadContextProvider } from '../typeahead/TypeaheadContext';
import { Navigation, NavigationApi } from '@weddingspot/ws-api-client';

export enum HeaderContextMenus {
    HAMBURGER = 'hamburger',
    SUBHEADER_LINKS = 'subheader',
    ACCOUNT = 'account',
    LOCATIONS = 'locations',
}

export enum HeaderSubmenus {
    MYACCOUNT = 'myaccount',
    BROWSE = 'browsevenues',
}

interface Props {
    // Event handlers for analytics
    headerItemClicked: (item: string) => void;
    headerLocationItemClicked: (item: string, name: string) => void;
    profileVersion?: string;
}

interface State {
    openedContextMenu: HeaderContextMenus | null;
    isContextMenuOpen: (target: HeaderContextMenus) => boolean;
    openContextMenu: (target: HeaderContextMenus) => void;
    closeContextMenu: (target: HeaderContextMenus) => void;

    openedSubmenu: HeaderSubmenus | null;
    isSubmenuOpen: (target: HeaderSubmenus) => boolean;
    openSubmenu: (target: HeaderSubmenus) => void;
    closeSubmenu: (target: HeaderSubmenus) => void;

    headerData: Navigation.SiteHeaderData | undefined;
    loadHeaderData: (venueId?: number, isRdFlow?: boolean) => Promise<void>;
}

interface HeaderContextType extends Props, State {}

const HeaderContext: React.Context<HeaderContextType> = React.createContext<HeaderContextType>({
    headerItemClicked: () => {},
    headerLocationItemClicked: () => {},
    openedContextMenu: null,
    isContextMenuOpen: () => false,
    openContextMenu: () => {},
    closeContextMenu: () => {},
    openedSubmenu: null,
    isSubmenuOpen: () => false,
    openSubmenu: () => {},
    closeSubmenu: () => {},
    headerData: undefined,
    loadHeaderData: () => Promise.resolve(),
    ...emtpyContext,
    profileVersion: '',
});

const HeaderContextConsumer = HeaderContext.Consumer;

/**
 * Context provider for header state, typeahead state, and management functions. Has some useful defaults
 * @param props header context data
 */
const HeaderContextProvider = (props: React.PropsWithChildren<Props & TypeaheadContext>) => {
    const [openedContextMenu, setOpenedContextMenu] = React.useState<HeaderContextMenus | null>(null);
    const isContextMenuOpen = React.useCallback((target: HeaderContextMenus) => target === openedContextMenu, [openedContextMenu]);
    const closeContextMenu = (target: HeaderContextMenus) => isContextMenuOpen(target) && setOpenedContextMenu(null);

    const [openedSubmenu, setOpenedSubmenu] = React.useState<HeaderSubmenus | null>(null);
    const isSubmenuOpen = React.useCallback((target: HeaderSubmenus) => target === openedSubmenu, [openedSubmenu]);
    const closeSubmenu = (target: HeaderSubmenus) => isSubmenuOpen(target) && setOpenedSubmenu(null);

    const [headerData, setHeaderData] = React.useState<Navigation.SiteHeaderData>();
    const loadData = React.useCallback((venueId?: number, isRdFlow?: boolean) => {
        return NavigationApi.getHeaderData(venueId, isRdFlow)
            .then((res) => {
                setHeaderData(res);
            })
            .catch();
    }, []);

    const defaultContext: Partial<HeaderContextType> = {
        openedContextMenu,
        isContextMenuOpen,
        openContextMenu: setOpenedContextMenu,
        closeContextMenu: closeContextMenu,
        openedSubmenu,
        isSubmenuOpen,
        openSubmenu: setOpenedSubmenu,
        closeSubmenu: closeSubmenu,
        headerData,
        loadHeaderData: loadData,
        profileVersion: props.profileVersion,
    };

    const typeaheadContextValues = {
        dataUrl: props.dataUrl,
        dataTTL: props.dataTTL,
        options: props.options,
        onSearchResultClicked: props.onSearchResultClicked,
    };

    return (
        <HeaderContext.Provider value={{ ...defaultContext, ...props } as HeaderContextType}>
            <TypeaheadContextProvider value={typeaheadContextValues}>{props.children}</TypeaheadContextProvider>
        </HeaderContext.Provider>
    );
};

export { HeaderContext, HeaderContextProvider, HeaderContextConsumer };
export type { HeaderContextType };
