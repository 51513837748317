import * as React from 'react';

import SimpleTrackedLink from './SimpleTrackedLink';
import HeaderRightLinksAuth, { HeaderRightLinksAuthBaseProps } from './HeaderRightLinksAuth';
import MessageCountBadge from './MessageCountBadge';
import { HeaderContext } from './HeaderContext';
import ReviewCountBadge from './ReviewCountBadge';
import { FeatureFlagContext, FLAG_VENUE_PROFILE_PAGE_REVIEW } from '../../providers/flag-context';

import styles from './Header.module.scss';
import { TrackedLogoutLink } from './TrackedLogoutLink';

// Main links component
const HeaderRightLinksVendor: React.SFC<HeaderRightLinksAuthBaseProps> = (props) => {
    const { headerItemClicked } = React.useContext(HeaderContext);
    const { flagEnabled } = React.useContext(FeatureFlagContext);

    const HeaderTrackedLink = (linkProps: { label: string; url: string; children?: React.ReactNode }) => {
        return <SimpleTrackedLink {...linkProps} onClick={headerItemClicked} />;
    };

    const renderMenuItems = () => {
        return (
            <>
                <li>
                    <HeaderTrackedLink label='Messages' url='/myaccount/messages/'>
                        Messages
                        {!!props.unreadMessageCount && <MessageCountBadge count={props.unreadMessageCount} />}
                    </HeaderTrackedLink>
                </li>
                {flagEnabled(FLAG_VENUE_PROFILE_PAGE_REVIEW) &&
                    <li>
                        <HeaderTrackedLink label='Reviews' url='/myaccount/reviews/'>
                            Reviews
                            {!!props.unreadReviewsCount && <ReviewCountBadge count={props.unreadReviewsCount} />}
                        </HeaderTrackedLink>
                    </li>
                }
                <li>
                    <HeaderTrackedLink label='My Leads' url='/myaccount/leads/' />
                </li>
                <li>
                    <HeaderTrackedLink label='Venue Information' url='/myaccount/edit-address/' />
                </li>
                <li>
                    <HeaderTrackedLink label='Venue Pricing' url='/myaccount/venue-rental-fees/' />
                </li>
                <li>
                    <HeaderTrackedLink label='Availability' url='/myaccount/availability/' />
                </li>
                <li>
                    <HeaderTrackedLink label='Account Access' url='/myaccount/account-access/' />
                </li>
                <li>
                    <HeaderTrackedLink label='Discounts' url='/myaccount/venue-discounts/' />
                </li>
                <li>
                    <HeaderTrackedLink label='Upcoming Events' url='/myaccount/leads/' />
                </li>
                <li>
                    <HeaderTrackedLink label='Get More Leads' url='/myaccount/upgrade-account/'>
                        Get More Leads <span className={styles.newBubble}>NEW!</span>
                    </HeaderTrackedLink>
                </li>
                <li>
                    <HeaderTrackedLink label='Venue Metrics' url='/myaccount/venue-metrics/' />
                </li>
                <li className={styles.divider} />
                <li>
                    <HeaderTrackedLink label='Help' url='/faq/' />
                </li>
                <li>
                    <HeaderTrackedLink label='Account Settings' url='/myaccount/contactinfo/' />
                </li>
                <li>
                    <TrackedLogoutLink />
                </li>
            </>
        );
    };

    return <HeaderRightLinksAuth {...props} renderMenuItems={renderMenuItems} />;
};

export default HeaderRightLinksVendor;
