import * as React from 'react';

import MwebHeaderMenuBase, { HeaderTrackedLink } from './MwebHeaderMenuBase';
import { WSAppContext } from '../../providers/ws-app';

import styles from './Header.module.scss';

export default class MwebHeaderMenuUnauth extends MwebHeaderMenuBase {
    renderMenuItems() {
        const { headerItemClicked } = this.context;

        return (
            <WSAppContext.Consumer>
                {({ openAuthModal }) => (
                    <div>
                        <div className={styles.mobileNavLinkWrapper}>
                            <HeaderTrackedLink label='Favorites' url='/myaccount/favorites/' className={styles.mobileNavLink} />
                        </div>
                        <div className={styles.mobileNavLinkWrapper}>
                            <HeaderTrackedLink label='Spot Estimates' url='/myaccount/spot-estimates/' className={styles.mobileNavLink} />
                        </div>
                        <div className={styles.mobileNavLinkWrapper}>
                            <HeaderTrackedLink label='Inquiries' url='/myaccount/appointments/' className={styles.mobileNavLink} />
                        </div>
                        <div className={styles.mobileNavLinkWrapper}>
                            <div className={styles.mobileNavLink}>
                                <div className={styles.mobileNavDivider} />
                            </div>
                        </div>
                        {this.renderBrowseVenuesNav()}
                        <div className={styles.mobileNavLinkWrapper}>
                            <HeaderTrackedLink label='Hotel Room Blocks' url='/hotels/' className={styles.mobileNavLink} />
                        </div>
                        <div className={styles.mobileNavLinkWrapper}>
                            <HeaderTrackedLink label='Blog' url='/blog/' className={styles.mobileNavLink} />
                        </div>
                        <div className={styles.mobileNavLinkWrapper}>
                            <HeaderTrackedLink label='Help' url='/myaccount/help/' className={styles.mobileNavLink} />
                        </div>
                        <div className={styles.mobileNavLinkWrapper}>
                            <a
                                href='#'
                                className={styles.mobileNavLink}
                                onClick={(e) => {
                                    headerItemClicked('Sign In');
                                    openAuthModal();
                                }}
                            >
                                Sign In
                            </a>
                        </div>
                        <HeaderTrackedLink label='Join as a Venue' url='/vendor-signup/' className={styles.mobileNavLink} />
                    </div>
                )}
            </WSAppContext.Consumer>
        );
    }
}
