import * as React from 'react';
import * as ReactDOM from 'react-dom';
import VenuesShoppingCartInquiryContent from './VenueShoppingCartInquiryContent';
import { ResponsiveModalContainer } from '../../responsive-modal';
import { CartItem } from '../../shopping-cart';
import { WSAppContext } from '../../../providers/ws-app';
import { VenuesShoppingCartModalStage } from './VenuesShoppingCartInquiryModal.types';

import styles from './VenueShoppingCartInquryContent.module.scss';

interface VenuesShoppingCartInquiryModalInterface {
    onClose?: () => void;
    items: CartItem[] | null;
    onSubmit: () => void;
}

const VenuesShoppingCartInquiryModal = (props: VenuesShoppingCartInquiryModalInterface) => {
    const { userIsAuthenticated, rootNodeId } = React.useContext(WSAppContext);

    const [currentStage, setCurrentStage] = React.useState<VenuesShoppingCartModalStage>(VenuesShoppingCartModalStage.INQUIRY_FORM);

    const [inquiryFormSubmitted, setInquiryFormSubmitted] = React.useState<boolean>(false);

    const resetModal = () => {
        setCurrentStage(VenuesShoppingCartModalStage.INQUIRY_FORM);
        setInquiryFormSubmitted(false);
    };

    const closeModal = () => {
        if (currentStage === VenuesShoppingCartModalStage.INQUIRY_FORM) {
            if (props.onClose) {
                props.onClose();
            }
        } else {
            resetModal();
        }
    };

    const getTitle = () => {
        switch (currentStage) {
            case VenuesShoppingCartModalStage.INQUIRY_FORM:
                return 'Message Venues';
            case VenuesShoppingCartModalStage.SUCCESS:
                return 'Success';
            case VenuesShoppingCartModalStage.RECOMMENDATIONS:
                return 'Explore recommendations, find your dream venue';
            case VenuesShoppingCartModalStage.GUEST_ROOMS:
                return 'Guest Rooms';
            default:
                return '';
        }
    };

    const rootElement = typeof window !== 'undefined' && document.getElementById(rootNodeId);

    if (!rootElement) {
        return null;
    }
    return ReactDOM.createPortal(
        <div className={styles.responsiveContainer} data-step='1'>
            <ResponsiveModalContainer
                show={!!props.items || inquiryFormSubmitted}
                headerContent={getTitle()}
                handleClickModalBackground={closeModal}
                handleClickModalClose={closeModal}
                bodyClassName={styles.responsiveContainer}
            >
                <VenuesShoppingCartInquiryContent
                    currentStage={currentStage}
                    setCurrentStage={setCurrentStage}
                    setInquiryFormSubmitted={setInquiryFormSubmitted}
                    closeModal={closeModal}
                    onFormSubmit={props.onSubmit}
                    items={props.items}
                    isAuthenticated={userIsAuthenticated}
                    resetModal={resetModal}
                />
            </ResponsiveModalContainer>
        </div>,
        rootElement
    );
};

export default VenuesShoppingCartInquiryModal;
