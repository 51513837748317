import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';

import './scss/material-text-input.module.scss';

export interface OwnProps {
    label: string;
    placeholder?: string;
    inputType?: string;
    mask?: string;
    handleOnBlur?: (value: string) => void;
    pattern?: string;
    helper?: string;
    ariaLabel?: string;
}
export type MaterialTextInputProps = WrappedFieldProps & OwnProps;

export class TextInput extends React.Component<MaterialTextInputProps, {}> {
    inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: MaterialTextInputProps) {
        super(props);
        this.inputRef = React.createRef();
    }

    /**
     * Reinitialize the input mask if one is specified. We need to
     * do this once after every render. Note: we can't do this in render because
     * the input ref is not defined.
     */
    initMask() {
        if (this.props.mask === undefined) {
            return;
        }

        const InputMask = require('inputmask');

        const mask = new InputMask({ mask: this.props.mask, showMaskOnHover: false });
        mask.mask(this.inputRef);
    }

    componentDidMount() {
        this.initMask();
    }

    componentDidUpdate() {
        this.initMask();
    }

    render() {
        const {
            label,
            input: { onFocus, onBlur, ...input },
            handleOnBlur,
            meta: { initial, error, touched },
            inputType,
            pattern,
            helper,
            ariaLabel,
        } = this.props;

        const hasValue = !!initial || input.value.length !== 0;

        const renderHelper = () => {
            return helper && !(touched && error) && <div className='MaterialTextInput--helper'>{helper}</div>;
        };

        return (
            <div className='MaterialTextInput'>
                <input
                    {...input}
                    type={inputType || 'text'}
                    pattern={pattern}
                    className={'MaterialTextInput--input' + (touched && error ? ' MaterialTextInput--input__has-error' : '')}
                    onBlur={(e: React.SyntheticEvent<HTMLInputElement>) => {
                        onBlur(e);
                        !!handleOnBlur && handleOnBlur(e.currentTarget.value);
                    }}
                    ref={this.inputRef}
                    aria-label={ariaLabel}
                />
                <div className='MaterialTextInput--bar' />
                <label className={'MaterialTextInput--label' + (hasValue ? ' MaterialTextInput--label__active' : '')}>{label}</label>
                {renderHelper()}
                {touched && error && <div className='MaterialTextInput--error'>{error}</div>}
            </div>
        );
    }
}

export default TextInput;
