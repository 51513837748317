import * as React from 'react';
import cls from 'classnames';

const SlideDown = require('react-slidedown').SlideDown;
import 'react-slidedown/lib/slidedown.css';
import SimpleTrackedLink from './SimpleTrackedLink';
import { SubheaderLink } from './types';
import { Navigation } from '@weddingspot/ws-api-client';
import { HeaderContext, HeaderContextType, HeaderContextMenus, HeaderSubmenus } from './HeaderContext';

import styles from './Header.module.scss';
const { HAMBURGER } = HeaderContextMenus;
const { BROWSE } = HeaderSubmenus;

export interface MwebHeaderMenuBaseProps {
    subheaderLinks: SubheaderLink[];
}

interface TrackedLinkProps {
    label: string;
    url: string;
    className?: string;
    children?: React.ReactNode;
}

export const HeaderTrackedLink = (linkProps: TrackedLinkProps) => {
    const { headerItemClicked } = React.useContext(HeaderContext);
    return <SimpleTrackedLink {...linkProps} onClick={headerItemClicked} />;
};

export default class MwebHeaderMenuBase<T = {}> extends React.Component<MwebHeaderMenuBaseProps & T> {
    static contextType: React.Context<HeaderContextType> = HeaderContext;
    // @ts-ignore
    context!: React.ContextType<typeof HeaderContext>;

    render() {
        const { isContextMenuOpen } = this.context;

        const menuOpen = isContextMenuOpen(HAMBURGER);
        return (
            <SlideDown className={cls(styles.mobileNav, styles.mobileNavCollapsed)}>
                {menuOpen && (
                    <div className={styles.mobileNavContentContainer} data-testid='MwebHeaderMenuBase--menu'>
                        {this.renderMenuItems()}
                    </div>
                )}
            </SlideDown>
        );
    }

    renderBrowseVenuesNav() {
        const { isSubmenuOpen, headerItemClicked } = this.context;

        let styleItems = {};
        this.props.subheaderLinks.map((item) => {
            if (item.type === Navigation.SiteHeaderLinkType.style && item.showOnMobile) {
                styleItems[item.label] = item;
            }
        });
        let itemsSorted = Object.keys(styleItems)
            .sort()
            .map((label) => styleItems[label]);
        itemsSorted.unshift({
            label: 'All Venues',
            url: '/wedding-venues/',
        });

        return (
            <div>
                <a
                    href='#'
                    className={styles.mobileNavLink}
                    onClick={(e) => {
                        e.preventDefault();
                        this.toggleSubmenu(BROWSE);
                        headerItemClicked('Browse Venues');
                    }}
                >
                    Browse Venues <i className={cls('icon-hb-arrowdown', styles.mobileNavCollapseIcon)} />
                </a>

                <SlideDown>
                    {isSubmenuOpen(BROWSE) && (
                        <div className={styles.mobileNavSubmenu}>
                            {itemsSorted.map((item, i) => (
                                <HeaderTrackedLink key={i} label={item.label} url={item.url} className={styles.mobileNavLink} />
                            ))}
                        </div>
                    )}
                </SlideDown>
            </div>
        );
    }

    renderMenuItems(): React.ReactNode | undefined {
        return undefined; // Implement by descendants
    }

    toggleMainmenu() {
        const { isContextMenuOpen, openContextMenu, closeContextMenu } = this.context;

        isContextMenuOpen(HAMBURGER) ? closeContextMenu(HAMBURGER) : openContextMenu(HAMBURGER);
    }

    toggleSubmenu(target: HeaderSubmenus) {
        const { isSubmenuOpen, openSubmenu, closeSubmenu } = this.context;
        isSubmenuOpen(target) ? closeSubmenu(target) : openSubmenu(target);
    }
}
