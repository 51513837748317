import React, { useCallback, useMemo, useState } from 'react';
import Button from '@cvent/carina/components/Button';
import { CheckoutVenueRecommendationsCardInterface } from '../../../checkout/Cards/Card';
import ChooseHotelsStep from './ChooseHotelsStep';
import { VenuesShoppingCartModalStage } from '../VenuesShoppingCartInquiryModal.types';

import styles from '../VenuesShoppingCartInquiryRecommendations.module.scss';
import FormStepComponent from './FormStep';
import SpinnerOverlay from '../../../spinner-overlay';
import { Appointments } from '@weddingspot/ws-api-client';

interface VenuesShoppingCartInquiryHotelsProps {
    hotelRecommendations: CheckoutVenueRecommendationsCardInterface[];
    setHotelRecommendations: (value: CheckoutVenueRecommendationsCardInterface[]) => void;
    setCurrentStage: (x: number) => void;
    submittedInquiryData: Appointments.MassInquiryRequestParams | null;
}

enum HotelsRecommendationsSteps {
    ChooseStep = 1,
    FormStep = 2,
}

const VenuesShoppingCartInquiryHotels = ({
    hotelRecommendations,
    setHotelRecommendations,
    setCurrentStage,
    submittedInquiryData,
}: VenuesShoppingCartInquiryHotelsProps) => {
    const [localStep, setLocalStep] = React.useState<HotelsRecommendationsSteps>(HotelsRecommendationsSteps.ChooseStep);
    const [isLoading, setIsLoading] = useState(false);
    const [RFPFormElement, setRFPFormElement] = React.useState<HTMLFormElement | null>(null);
    const changeIsChecked = React.useCallback(
        (id: number | string) => {
            setHotelRecommendations(
                hotelRecommendations.map((item: CheckoutVenueRecommendationsCardInterface) => {
                    if (item.id === id) {
                        item.isChecked = !item.isChecked;
                    }
                    return item;
                })
            );
        },
        [hotelRecommendations]
    );
    const nextStepHandler = useCallback(() => {
        setLocalStep(HotelsRecommendationsSteps.FormStep);
    }, []);
    const submitHandler = () => {
        RFPFormElement?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    };

    const defaultFormValue = useMemo(() => {
        if (!submittedInquiryData) {
            return undefined;
        } else {
            return {
                // @ts-ignore
                email: submittedInquiryData.email,
                // @ts-ignore
                firstName: submittedInquiryData.first_name,
                // @ts-ignore
                lastName: submittedInquiryData.last_name,
                // @ts-ignore
                phoneNumber: submittedInquiryData.phone_number,
            };
        }
    }, [submittedInquiryData]);
    return (
        <SpinnerOverlay
            spinnerProps={{
                spinnerColor: '#881155',
                spinnerDiameter: 40,
            }}
            isSpinning={isLoading}
        >
            {localStep === HotelsRecommendationsSteps.ChooseStep ? (
                <ChooseHotelsStep hotelRecommendations={hotelRecommendations} changeIsChecked={changeIsChecked} />
            ) : (
                <FormStepComponent
                    hotelRecommendations={hotelRecommendations.filter((i) => i.isChecked)}
                    changeIsChecked={changeIsChecked}
                    onSuccess={() => {
                        setCurrentStage(VenuesShoppingCartModalStage.SUCCESS);
                    }}
                    setIsLoading={setIsLoading}
                    setRFPFormElement={setRFPFormElement}
                    defaultFormValue={defaultFormValue}
                />
            )}
            <div className={styles.buttons}>
                <Button
                    text='Skip'
                    onClick={() => {
                        setCurrentStage(VenuesShoppingCartModalStage.SUCCESS);
                    }}
                    appearance={'lined'}
                    accessibilityLabel={'Skip'}
                />
                {hotelRecommendations.some((item) => item.isChecked) && (
                    <>
                        {localStep === HotelsRecommendationsSteps.ChooseStep ? (
                            <Button text='Next' onClick={nextStepHandler} appearance={'filled'} accessibilityLabel={'Next'} />
                        ) : (
                            <Button text='Send' onClick={submitHandler} appearance={'filled'} accessibilityLabel={'Send'} />
                        )}
                    </>
                )}
            </div>
        </SpinnerOverlay>
    );
};

export default VenuesShoppingCartInquiryHotels;
