import * as React from 'react';
import { Form, Formik, FormikHelpers as FormikActions, FormikProps } from 'formik';
import { ResponsiveModalContainer } from '../responsive-modal';
import { VendorsApi } from '@weddingspot/ws-api-client';
import ReportDMCAForm, { ReportDMCAFormProps } from './FormReportDMCA';

interface ReportDMCAModalProps {
    showModal: boolean;
    handleClose: () => void;
    venueId: number;
}

interface State {
    formSubmitted: boolean;
}

const MODAL_TITLE = 'Report Photo(s)';

const initialFormValues = {
    email: '',
    message: '',
};

/**
 * Modal for reporing DMCA issues
 */
export default class ReportDMCAModal extends React.Component<ReportDMCAModalProps, State> {
    constructor(props: ReportDMCAModalProps) {
        super(props);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.setFormDoneSubmitting = this.setFormDoneSubmitting.bind(this);
        this.renderModal = this.renderModal.bind(this);
        this.renderBodyContent = this.renderBodyContent.bind(this);
        this.renderFooterContent = this.renderFooterContent.bind(this);
    }

    readonly state = {
        formSubmitted: false,
    };

    private setFormDoneSubmitting() {
        this.setState({ formSubmitted: true });
    }

    private handleFormSubmit(formData: ReportDMCAFormProps, { setSubmitting, setStatus }: FormikActions<ReportDMCAFormProps>) {
        setSubmitting(true);
        VendorsApi.reportImages({
            venue_id: this.props.venueId,
            email: formData.email,
            report_reason: formData.report_reason,
            message: formData.message,
        })
            .then((_) => {
                setSubmitting(false);
                this.setFormDoneSubmitting();
            })
            .catch((_) => {
                setStatus('An error occured while atempting to send your report. Please try again later.');
                setSubmitting(false);
            });
    }

    renderBodyContent(formikProps: FormikProps<ReportDMCAFormProps>) {
        if (this.state.formSubmitted) {
            return <div>Your request has been submitted. Our team will review it and contact you for further details</div>;
        }

        return <ReportDMCAForm {...formikProps} />;
    }

    renderFooterContent(formikProps: FormikProps<ReportDMCAFormProps>) {
        if (this.state.formSubmitted) {
            return (
                <div>
                    <button className='nx-button' onClick={this.props.handleClose}>
                        OK
                    </button>
                </div>
            );
        }

        return (
            <div>
                <button className='nx-button nx-button--primary' type='submit' disabled={formikProps.isSubmitting}>
                    Submit
                </button>
                <button className='nx-button' onClick={this.props.handleClose} disabled={formikProps.isSubmitting}>
                    Cancel
                </button>
            </div>
        );
    }

    renderModal(formikProps: FormikProps<ReportDMCAFormProps>) {
        return (
            <Form>
                <ResponsiveModalContainer
                    show={this.props.showModal}
                    handleClickModalBackground={this.props.handleClose}
                    handleClickModalClose={this.props.handleClose}
                    headerContent={MODAL_TITLE}
                    footerContent={this.renderFooterContent(formikProps)}
                    noFullscreen={true}
                >
                    {this.renderBodyContent(formikProps)}
                </ResponsiveModalContainer>
            </Form>
        );
    }

    render() {
        return (
            <div>
                <Formik initialValues={initialFormValues} onSubmit={this.handleFormSubmit} render={this.renderModal} />
            </div>
        );
    }
}
