import React from 'react';
import { CheckoutVenueRecommendationsCardInterface } from '../../../checkout/Cards/Card';
import { CardItemComponent } from '../../../shopping-cart';

import styles from './VenuesShoppingCartInquiryHotels.module.scss';
import { HotelFormConnected } from '../../../appointments';

interface FormStepProps {
    hotelRecommendations: CheckoutVenueRecommendationsCardInterface[];
    changeIsChecked: (value: number | string) => void;
    onSuccess: () => void;
    setIsLoading: (values: boolean) => void;
    setRFPFormElement: (el: HTMLFormElement | null) => void;
    defaultFormValue?: {
        email: string;
        firstName: string;
        lastName: string;
        phoneNumber: string;
    };
}

const FormStep = ({
    hotelRecommendations,
    changeIsChecked,
    onSuccess,
    setIsLoading,
    setRFPFormElement,
    defaultFormValue,
}: FormStepProps) => {
    return (
        <div className={styles.venuesList}>
            <h2>
                {hotelRecommendations.length} hotel{hotelRecommendations.length > 1 ? 's' : ''} selected
            </h2>
            <div className={styles.hotelCards}>
                {hotelRecommendations.map((hotel) => (
                    <CardItemComponent
                        id={hotel.id}
                        title={hotel.title}
                        subtitle={hotel.location}
                        key={hotel.id}
                        rating={hotel.rating}
                        image={hotel.image}
                        onClickRemove={changeIsChecked}
                    />
                ))}
            </div>
            <HotelFormConnected
                type='checkout'
                onClose={() => null}
                onSuccess={onSuccess}
                handleRoomCountSubmit={() => null}
                handleClickedSpecifyGuestRooms={() => null}
                csnVenueData={hotelRecommendations.map((hotel) => ({ id: String(hotel.id) }))}
                hideButtons={true}
                leadSource={'room blocks search'}
                onSubmitting={(submit: boolean) => setIsLoading(submit)}
                onFormInit={(el: HTMLFormElement | null) => setRFPFormElement(el)}
                defaultFormValue={defaultFormValue}
            />
        </div>
    );
};

export default FormStep;
