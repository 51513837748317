import * as React from 'react';
import { useContext } from 'react';
import { HeaderContext } from './HeaderContext';
import { getCookieValue } from '@weddingspot/ws-api-client';

type TrackedLogoutLinkProps = {
    className?: string;
};

/**
 * Logout link which calls the post endpoint /logout/ of django server.
 * Since the post endpoint expects a csrftoken in request body as well as csrftoken cookie,
 * we are adding the csrftoken as the hidden input field to prevent csrf attack.
 *
 * On click of logout link, it also tracks that the logout link is clicked for analytics purpose.
 * @param className
 * @constructor
 */
export const TrackedLogoutLink = ({ className }: TrackedLogoutLinkProps) => {
    const { headerItemClicked } = useContext(HeaderContext);
    const csrftoken = getCookieValue('csrftoken') as string;

    const removeReviewCountSessionData = () => {
        Object.keys(sessionStorage)
            .filter(function(key) { return /reviewData/.test(key); })
            .forEach(function(key) {
                sessionStorage.removeItem(key);
            });
    };

    return (
        <form
            action={'/logout/'}
            method={'post'}
            onSubmit={() => {
                removeReviewCountSessionData();
                headerItemClicked('Log Out');
            }}
        >
            <input name='csrfmiddlewaretoken' type={'hidden'} value={csrftoken} />
            <button className={className} type={'submit'}>
                Log Out
            </button>
        </form>
    );
};
