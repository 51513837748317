import * as React from 'react';
import cls from 'classnames';

import styles from './Header.module.scss';

// Unread message count badge that shows up in a couple places
const MessageCountBadge: React.SFC<{ count: number }> = (props) => {
    return <span className={cls(styles.messagesBadge)}>{props.count}</span>;
};

export default MessageCountBadge;
